import {
  TableRow,
  TableCell,
  Checkbox,
  Stack,
  Avatar,
  Typography,
  IconButton,
  Popover,
  MenuItem,
} from "@mui/material";
import { Label } from "../../../core/Label";
import Iconify from "../../../core/Iconify";
import { useState, MouseEvent } from "react";
import { ISaleReport } from "../../../models/Report/ISaleReport";
// ----------------------------------------------------------------------
interface IProps {
  data: ISaleReport;
  index: number;
}
// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  const [open, setOpen] = useState<HTMLButtonElement | null>(null);
  const [oModel, setOModel] = useState(false);
  const [oDelete, setODelete] = useState(false);
  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const onModelHandler = () => {
    setOModel(!oModel);
  };

  const onMDeleteHandler = () => {
    setODelete(!oDelete);
  };

  const hCloseModel = () => {
    setOModel(false);
    setODelete(false);
  };

  return (
    <>
      <TableRow hover role="checkbox" key={index} sx={{ cursor: "pointer" }}>
        <TableCell padding="checkbox">
          <Checkbox color="primary" />
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              alt={data.productName}
              src={`/assets/images/avatars/avatar_${index + 1}.jpg`}
            />
            <Typography variant="subtitle2" noWrap>
              {data.productName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">{data.totalRevenue}</TableCell>
        <TableCell align="left">{data.totalQuantitySold}</TableCell>

        <TableCell align="center">
          <Label color={(index / 2 == 0 && "error") || "success"}>
            {data.rate}
          </Label>
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>
      {/* <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        <MenuItem onClick={handleCloseMenu}>
          <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <Link to={`/vessel/1`}>
          <MenuItem sx={{ color: "info.main" }}>
            <Iconify icon="eva:eye-outline" sx={{ mr: 2 }} />
            View
          </MenuItem>
        </Link>
        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover> */}

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        <MenuItem onClick={onModelHandler}>
          <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: "error.main" }} onClick={onMDeleteHandler}>
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {/* <Update
        open={oModel}
        handleClose={hCloseModel}
        onModelHandler={onModelHandler}
        title="Update An Product"
        subTitle="Please fill the fields "
        data={data}
      />
      <Delete
        open={oDelete}
        handleClose={hCloseModel}
        onModelHandler={onMDeleteHandler}
        title="Are You Sure Want to Delete"
        subTitle=""
        data={data}
      /> */}
    </>
  );
};
export default TRow;
