import create from "zustand";
type Store = {
  mobile: string;
  setMobile: (mobile: string) => void; // Optional parameter to allow clearing the data
};

const useMobileStore = create<Store>((set) => ({
  mobile: "",
  setMobile(mobile: string) {
    set({ mobile: mobile });
  },
}));

export default useMobileStore;
