import React, { CSSProperties } from "react";
import { BillPayment } from "../../models/IBillPrint";
import { roundNumber } from "../../hooks/roundNumber";

interface IAmt {
  cashAmt: number;
}

const styles: Record<string, CSSProperties> = {
  body: {
    width: "60mm",
    fontFamily: "Space Mono ,monospace",
    fontSize: "8px",
    textAlign: "left",
    margin: "0 auto",
  },
  headerFooter: {
    // textAlign: "center",
    width: "100%",
    borderBottom: "2px dashed",
    marginTop: "2px",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    // height: "100%",
    padding: 0,
    margin: 0,
    // height: "100%",
  },
  headerLogo: {
    textAlign: "center",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    tableLayout: "fixed",
    borderBottom: "2px dashed",
  },
  cell: {
    padding: "1px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    verticalAlign: "top",
    width: "25%",
  },
  cellProductName: {
    width: "25%",
    whiteSpace: "normal",
  },
  cellSmall: {
    width: "15%",
    textAlign: "center",
  },
  tdFont: {
    fontSize: "15px",
  },
  TotalAmout: {
    fontSize: "22px",
    fontWeight: "bold",
  },
  Unbold: {
    fontWeight: "normal",
    fontSize: "15px",
  },
  right: {
    textAlign: "left",
  },
  // dashedLine: {
  //   borderBottom: "2px dashed",
  // },
  textHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textHeading2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "2px dashed",
    borderTop: "2px dashed",
    margin: 0,
    padding: 0,
  },
  qrStyle: {
    width: "100px",
    height: "100px",
    border: "1px solid #000",
  },
};

const Receipt: React.FC<{ billPayment: BillPayment; cashAmt?: number }> = ({
  billPayment,
  cashAmt,
}) => (
  <div style={styles.body}>
    <div style={styles.headerFooter}>
      <div style={styles.headerLogo}>
        <h1 style={{ fontWeight: 700 }}>WorldQart Fresh</h1>
      </div>
      <h2 style={{ textAlign: "center" }}>
        Plot No. 4, Sec 3,
        <br />
        Opp Peace Park Corner,
        <br /> Udaipur - 313002.
      </h2>
      <h2 style={{ textAlign: "center" }}>Help: +91 9460959699/2943573355 </h2>
      <h2 style={{ textAlign: "center" }}>FSSAI Lic No: 22224088000366 </h2>
    </div>
    <div style={styles.headerFooter}>
      <h2
        style={{
          textAlign: "center",
          paddingTop: 0,
          paddingBottom: 0,
          margin: 0,
          marginTop: "5px",
        }}
      >
        **INVOICE**
      </h2>

      <div style={styles.spaceBetween}>
        <h2>
          Date: {new Date().toISOString().split("T")[0]}{" "}
          {new Date().toTimeString().split(" ")[0]}
        </h2>
        <h2> Bill:#{billPayment.billNo}</h2>
      </div>
    </div>
    <table style={styles.table}>
      <thead
        style={{
          ...styles.dashedLine,
        }}
      >
        <tr>
          <th
            style={{
              ...styles.cell,
              ...styles.cellProductName,
              ...styles.Unbold,
              ...styles.right,
            }}
          >
            ItemName
          </th>
          <th style={{ ...styles.cell, ...styles.cellSmall, ...styles.Unbold }}>
            WT/Qty
          </th>
          <th style={{ ...styles.cell, ...styles.cellSmall, ...styles.Unbold }}>
            Rate
          </th>
          <th style={{ ...styles.cell, ...styles.cellSmall, ...styles.Unbold }}>
            Amt₹
          </th>
        </tr>
      </thead>
      <tbody>
        {billPayment.products.map((product, index) => (
          <tr key={index}>
            <td
              style={{
                ...styles.cell,
                ...styles.cellProductName,
                ...styles.tdFont,
              }}
            >
              {product.productName}
            </td>
            <td
              style={{ ...styles.cell, ...styles.cellSmall, ...styles.tdFont }}
            >
              {product.quantity.toFixed(2)}
            </td>
            <td
              style={{ ...styles.cell, ...styles.cellSmall, ...styles.tdFont }}
            >
              {product.rate}
            </td>
            <td
              style={{ ...styles.cell, ...styles.cellSmall, ...styles.tdFont }}
            >
              {(product.quantity * product.rate).toFixed(2)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>

    <div style={styles.headerFooter}>
      <h2 style={styles.textHeading}>
        Total : <span>{billPayment.totalBillAmount.toFixed(2)}</span>
      </h2>
      <h2 style={styles.textHeading}>
        Round Off:{" "}
        <span>
          {billPayment.totalBillAmount -
            roundNumber(billPayment.totalBillAmount) >
          0
            ? "-"
            : "+"}
          {Math.abs(
            roundNumber(billPayment.totalBillAmount) -
              billPayment.totalBillAmount
          ).toFixed(2)}
        </span>
      </h2>

      <h2 style={styles.textHeading2}>
        Net:{" "}
        <span style={styles.TotalAmout}>
          ₹ {roundNumber(billPayment.totalBillAmount).toFixed(0)}
        </span>
      </h2>

      <div style={{ margin: 0, padding: 0 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",

            padding: 0,
            margin: 0,
          }}
        >
          <div>
            <h3> Items : {billPayment.totalItems} </h3>
          </div>
          <div>
            <h3> Qty : {billPayment.totalQuantity.toFixed(3)} kg </h3>
          </div>
          <div>
            <h3> PCS : 0 </h3>
          </div>
        </div>
        {cashAmt && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",

              padding: 0,
              margin: 0,
              marginTop: "-13px",
            }}
          >
            <div>
              <h3> Cash : {cashAmt} </h3>
            </div>

            <div>
              <h3>
                {" "}
                Change : {cashAmt -
                  roundNumber(billPayment.totalBillAmount)}{" "}
              </h3>
            </div>
          </div>
        )}
      </div>

      <h3
        style={{ textAlign: "center", fontSize: "12px", margin: 0, padding: 0 }}
      >
        Thank you for Shopping with Us
      </h3>
      <h3
        style={{
          textAlign: "center",
          fontSize: "12px",
          margin: 0,
          padding: 0,
          paddingBottom: "5px",
        }}
      >
        Shop Online On www.worldqartfresh.com
      </h3>
    </div>
  </div>
);

export default Receipt;
