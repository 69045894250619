import { CircularProgress } from "@mui/material";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ChangePassword, Login } from "./pages/Auth";
import useTokenStore from "./store/token";
import NotFoundPage from "./components/CommonPage/NotFoundPage";
import POSOrder from "./pages/POS-Order/POSOrder";
import ProtectedRoute from "./components/ProtectedRoute";
import { SaleReport } from "./pages/SaleRepot";
import StockReport from "./pages/StockReport/StockReport";
const Dashbord = lazy(() => import("./pages/dashboard"));

const Path = () => {
  const { token } = useTokenStore((state) => ({
    token: state.token,
  }));

  return (
    <>
      <Suspense fallback={<CircularProgress disableShrink />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/change-password" element={<ChangePassword />} />

          <Route element={<ProtectedRoute />}>
            <Route
              path="/"
              element={
                <Suspense fallback={<CircularProgress disableShrink />}>
                  <POSOrder />
                </Suspense>
              }
            />
            <Route
              path="/sale-report"
              element={
                <Suspense fallback={<CircularProgress disableShrink />}>
                  <SaleReport />
                </Suspense>
              }
            />
            <Route
              path="/Stock-report"
              element={
                <Suspense fallback={<CircularProgress disableShrink />}>
                  <StockReport />
                </Suspense>
              }
            />
          </Route>

          {/* <Route path="/" element={<POSOrder />} /> */}

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </>
  );
};
export default Path;
