import { useState } from "react";

import { List } from "./core";
import { Stack } from "@mui/material";
import H1 from "../../core/H1";

function StockReport() {
  return (
    <>
      {/* <MainHead title="Sale Report" /> */}
      <Stack direction={"row"} px={3} justifyContent={"space-between"} py={3}>
        <H1 title="Stock Report" />
      </Stack>
      <List />
    </>
  );
}

export default StockReport;
