import create from "zustand";

type Store = {
  PCS: string;
  setPCS: (data: string) => void; // Optional parameter to allow clearing the data
};

const usePCSStore = create<Store>((set) => ({
  PCS: "0",
  setPCS(PCS: string) {
    set({ PCS: PCS });
  },
}));

export default usePCSStore;
