import { Button, Stack } from "@mui/material";
import { BasicSearch } from "../../../../core/SearchBar";
import useMobile from "../../../../hooks/useMobile";
// ----------------------------------------------------------------------
interface IProps {
  searchInputHandler: (value: string) => void;
  searchInput: string;
}
// ----------------------------------------------------------------------

const THeader = ({ searchInputHandler, searchInput }: IProps) => {
  const isMobile = useMobile();

  return (
    <Stack
      direction={!isMobile ? "row" : "column"}
      alignItems="center"
      justifyContent={"space-between"}
      p={2}
    >
      <Stack
        direction={"row"}
        gap={2}
        width={isMobile ? "100%" : undefined}
        justifyContent={isMobile ? "space-between" : undefined}
      >
        <BasicSearch
          searchInputHandler={searchInputHandler}
          searchInput={searchInput}
          width={"250px"}
          placeHolder="Search Product"
        />
      </Stack>
    </Stack>
  );
};
export default THeader;
