import { Autocomplete, TextField } from "@mui/material";
import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { ProductServices } from "../services/ProductServices";
import { IProduct } from "../models/POSModel/IProduct";
import { styled } from "@mui/system";

// Props interface for the component
interface IProps {
  inputHandler: (value: IProduct | null) => void;
  isFullWidth?: boolean;
  isValid?: boolean;
}

// Interface for the ref actions to expose methods to parent components
interface AllProductActions {
  clearInput: () => void;
}

const AllProduct = forwardRef<AllProductActions, IProps>(
  ({ inputHandler, isFullWidth = false, isValid = false }, ref) => {
    const [list, setList] = useState<IProduct[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const inputRef = useRef<HTMLInputElement>(null);

    // Fetches all products and sets the list
    const getAllProductList = async () => {
      try {
        const response = await ProductServices.getAllProductApi(1, 200);
        if (response.status === 200 && response.data) {
          setList(response.data);
        }
      } catch (error) {
        console.error("Error fetching products", error);
      }
    };

    // ComponentDidMount lifecycle to fetch products and focus the input
    useEffect(() => {
      getAllProductList();
      // Focus the input field on mount
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    // Method to clear the input field and notify the parent via `inputHandler`
    const clearInput = () => {
      setInputValue("");
      inputHandler(null);
    };

    // Expose the `clearInput` method to parent components through `ref`
    useImperativeHandle(ref, () => ({
      clearInput,
    }));

    // Handles changes in the Autocomplete input
    const handleInputChange = (
      event: React.ChangeEvent<{}>,
      newInputValue: string
    ) => {
      setInputValue(newInputValue);
    };

    return (
      <Autocomplete
        freeSolo
        sx={{
          width: isFullWidth ? "100%" : 300,
          "& .MuiAutocomplete-input": {
            fontWeight: 700,
            fontSize: "18px",
          },
        }}
        disableClearable
        options={list.map((option) => option.name)}
        value={inputValue}
        onInputChange={handleInputChange}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          newValue: string | null
        ) => {
          const matchingProduct = list.find(
            (product) => product.name === newValue
          );
          inputHandler(matchingProduct ? matchingProduct : null);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search a Product"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
            inputRef={inputRef}
            error={!!isValid}
            helperText={isValid ? "Select a valid product" : ""}
          />
        )}
        componentsProps={{
          popper: {
            sx: {
              "& .MuiAutocomplete-option": {
                fontWeight: "bold",
              },
            },
          },
        }}
      />
    );
  }
);

export default AllProduct;
