import {
  Container,
  Typography,
  Paper,
  Box,
  Stack,
  Button,
} from "@mui/material";
import ModelOpen from "../../../core/Model copy";
import { useEffect, useState } from "react";
import { OutlineInput } from "../../../core/InputField";
import { IState } from "../../../models/IState";
import { BillService } from "../../../services/BillServices";
import ReactDOMServer from "react-dom/server";
import Receipt from "../../../components/Bill/Bill";
import { printReceipt } from "../../../hooks/printReceipt";

export interface IModel {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  title?: string;
  subTitle?: string;
}

const PrintBillModel = ({ open, handleClose, title, subTitle }: IModel) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [billNo, setBillNo] = useState<number>(0);
  const [billValid, setBillValid] = useState({
    isValid: true,
    message: "",
  });

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const billNoHandlerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBillNo(+event.target.value);
    // validateEmail(event.target.value);
  };

  // useEffect(() => {
  //   getBillNoByHandler(12);
  // }, []);

  const getBillNoByHandler = async (billNo: number) => {
    try {
      // setLoader(true);

      setState({
        ...state,
        loader: true,
      });

      const response = await BillService.getBillNoByIdAlpi(billNo);
      if (response.status === 200 && response.data) {
        console.log(response.data.billPayment.paymentMode);
        if (response.data.billPayment.paymentMode) {
          const receiptHtml = ReactDOMServer.renderToString(
            <Receipt billPayment={response.data.billPayment} />
          );
          printReceipt(receiptHtml);
        } else {
          alert("Payment Pending ");
        }
      }
    } catch (error) {
      console.error("Error fetching products", error);
      alert("Error");
    } finally {
      setState({
        ...state,
        loader: false,
      });
    }
  };

  useEffect(() => {
    setBillNo(0);
  }, [open]);

  const { loader, tosted, message, severity } = state;

  return (
    <ModelOpen
      open={open}
      handleClose={handleClose}
      onModelHandler={() => console.log("onModelHandler")}
      title={title}
      subTitle={subTitle}
      width="450px"
    >
      <Container
        maxWidth="lg"
        component={Paper}
        elevation={2}
        sx={{ width: "100%" }}
      >
        <Typography variant="h4" textAlign={"start"} gutterBottom>
          {title}
        </Typography>
        <Typography textAlign={"start"} gutterBottom>
          {subTitle}
        </Typography>
        <Box py={2}>
          <OutlineInput
            value={billNo}
            label="Enter An Bill No"
            type="number"
            handleInputChange={billNoHandlerChange}
            error={billValid.isValid === false ? billValid.message : undefined}
            disabled={loader}
            autoFocus={true}
          />

          <Stack direction={"row"} gap={"10px"} py={2}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={() => getBillNoByHandler(billNo)}
            >
              Print
            </Button>
          </Stack>
        </Box>
      </Container>
    </ModelOpen>
  );
};

export default PrintBillModel;
