// import Box from "@mui/material/Box";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import Drawer from "@mui/material/Drawer";
// import Toolbar from "@mui/material/Toolbar";
// import { Avatar, ListItemIcon, Button } from "@mui/material";
// import { isAllNav } from "./Data";
// import useUserStore from "../../store/userData";
// import { NavCard } from "./core";
// import useMobile from "../../hooks/useMobile";
// import useIsDrawerStore from "../../store/isDrawer";
// import Iconify from "../../core/Iconify";
// import useTokenStore from "../../store/token";
// import Cookies from "universal-cookie";
// import { useNavigate } from "react-router-dom";

// const SideNav = () => {
//   const cookies = new Cookies();
//   const { data } = useUserStore((state) => ({
//     data: state.data,
//   }));

//   const { token, setToken } = useTokenStore((state) => ({
//     token: state.token,
//     setToken: state.setToken,
//   }));
//   const navigate = useNavigate();

//   const logOutHandler = () => {
//     cookies.remove("pos-token", {
//       path: "/",
//       domain: window.location.hostname,
//     });
//     setToken(null);
//     navigate("/login");
//   };

//   const { open, setOpen } = useIsDrawerStore((state) => ({
//     open: state.open,
//     setOpen: state.setOpen,
//   }));

//   const handleDrawerToggle = () => {
//     setOpen(!open);
//   };

//   const drawerWidth = 280;

//   return (
//     <Drawer
//       sx={{
//         width: drawerWidth,
//         position: "relative",
//         flexShrink: 0,
//         "& .MuiDrawer-paper": {
//           width: drawerWidth,
//           boxSizing: "border-box",
//           background: "rgb(249,250,251)",
//         },
//       }}
//       anchor="left"
//       // variant={isMobile ? "temporary" : "permanent"}
//       variant={"temporary"}
//       open={open}
//       // open={isMobile ? open : true}
//       onClose={handleDrawerToggle}
//       ModalProps={{
//         keepMounted: true, // Better open performance on mobile.
//       }}
//     >
//       <Toolbar></Toolbar>
//       <Box sx={{ position: "relative", height: "100%" }}>
//         <Box p={1.3}>
//           <List>
//             <ListItem
//               sx={{
//                 height: "80px",
//                 border: (theme) => `1px dashed ${theme.palette.divider}`,
//                 mb: 2,
//               }}
//               selected
//             >
//               <ListItemIcon>
//                 <Avatar
//                   src={"/assets/images/avatars/avatar_25.png"}
//                   sx={{ width: "45px", height: "45px", mr: "10px" }}
//                 />
//               </ListItemIcon>
//               <ListItemText primary={data?.name} secondary={data?.role} />
//             </ListItem>
//           </List>

//           <List>
//             {isAllNav.map((data, index) => {
//               return <NavCard data={data} key={index} />;
//             })}
//           </List>
//         </Box>
//         <Box sx={{ width: "100%", p: 1.5 }}>
//           <Button
//             fullWidth
//             size="large"
//             variant="contained"
//             endIcon={<Iconify icon={"heroicons-outline:logout"} />}
//             onClick={logOutHandler}
//           >
//             Logout
//           </Button>
//         </Box>
//       </Box>
//     </Drawer>
//   );
// };
// export default SideNav;

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { Avatar, ListItemIcon, Button } from "@mui/material";
import { isAllNav } from "./Data";
import useUserStore from "../../store/userData";
import { NavCard } from "./core";
import useMobile from "../../hooks/useMobile";
import useIsDrawerStore from "../../store/isDrawer";
import Iconify from "../../core/Iconify";
import useTokenStore from "../../store/token";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const SideNav = () => {
  const cookies = new Cookies();
  const { data } = useUserStore((state) => ({
    data: state.data,
  }));

  const { token, setToken } = useTokenStore((state) => ({
    token: state.token,
    setToken: state.setToken,
  }));
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login if token is null
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  const logOutHandler = () => {
    // Remove the cookie
    cookies.remove("pos-token", {
      path: "/",
      domain: window.location.hostname,
    });
    // Update the token state to null
    setToken(null);
  };

  const { open, setOpen } = useIsDrawerStore((state) => ({
    open: state.open,
    setOpen: state.setOpen,
  }));

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const drawerWidth = 280;

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        position: "relative",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          background: "rgb(249,250,251)",
        },
      }}
      anchor="left"
      variant={"temporary"}
      open={open}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Toolbar></Toolbar>
      <Box sx={{ position: "relative", height: "100%" }}>
        <Box p={1.3}>
          <List>
            <ListItem
              sx={{
                height: "80px",
                border: (theme) => `1px dashed ${theme.palette.divider}`,
                mb: 2,
              }}
              selected
            >
              <ListItemIcon>
                <Avatar
                  src={"/assets/images/avatars/avatar_25.png"}
                  sx={{ width: "45px", height: "45px", mr: "10px" }}
                />
              </ListItemIcon>
              <ListItemText primary={data?.name} secondary={data?.role} />
            </ListItem>
          </List>

          <List>
            {isAllNav.map((data, index) => {
              return <NavCard data={data} key={index} />;
            })}
          </List>
        </Box>
        <Box sx={{ width: "100%", p: 1.5 }}>
          <Button
            fullWidth
            size="large"
            variant="contained"
            endIcon={<Iconify icon={"heroicons-outline:logout"} />}
            onClick={logOutHandler}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideNav;
