import create from "zustand";
import { IUnpaidBill } from "../../models/POSModel/IUnpaidBill";

type Store = {
  data: IUnpaidBill[] | undefined;
  setData: (data: IUnpaidBill[] | undefined) => void; // Optional parameter to allow clearing the data
};

const useUnpaidBillStore = create<Store>((set) => ({
  data: undefined,
  setData(data: IUnpaidBill[] | undefined) {
    set({ data: data });
  },
}));

export default useUnpaidBillStore;
