import create from "zustand";
import { IBillList } from "../../models/POSModel/IBillList";

type Store = {
  data: IBillList | undefined;
  setData: (data: IBillList | undefined) => void; // Optional parameter to allow clearing the data
};

const useBillListStore = create<Store>((set) => ({
  data: undefined,
  setData(data: IBillList | undefined) {
    set({ data: data });
  },
}));

export default useBillListStore;
