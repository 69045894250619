import { InstanceAxios } from "../../hooks/axiosConfig";

export class StockReportService {
  public static getReportApi(start: string, end: string) {
    return InstanceAxios().get("/reports/fetchTotalStockPurchase", {
      params: {
        startDate: start,
        endDate: end,
      },
    });
  }
}
