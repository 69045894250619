import axios from "axios";
import { InstanceAxios } from "../../hooks/axiosConfig";
export class LoginService {
  public static loginHandler(email: string, password: string) {
    const data = {
      email,
      password,
    };
    return axios.post(`${process.env.REACT_APP_API_URL}/user/login`, data);
  }
  public static changePasswordApi(
    currentPassword: string,
    newPassword: string
  ) {
    const data = {
      currentPassword,
      newPassword,
    };
    return InstanceAxios().post(`/user/changepassword`, data);
  }
}
