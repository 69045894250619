import { useEffect, useState } from "react";
import { TPagination } from "../../../core/Pagination";
import { Paper, TableBody, TablePagination } from "@mui/material";
import THeader from "../../../core/THeader copy";
import MTable from "./MTable";
import THead from "./THead";
import Loader from "./Loader";
import TRow from "./TRow";
import { IProduct } from "../../../models/IProduct";
import { IState } from "../../../models/IState";
import { ProductServices } from "../../../services/ProductServices";
import Tosted from "../../../core/Tosted";
// import useIsProductStore from "../../../store/isProduct";
import useIsProductStore from "../../../store/isProduct";
import { getTodayDate } from "../../../hooks/getTodayDate";
import { SaleReportService } from "../../../services/report/SaleRepot";
import { ISaleReport } from "../../../models/Report/ISaleReport";
import { StockReportService } from "../../../services/report/StockReport";
import { IStockReport } from "../../../models/Report/IStockReport";

const List = () => {
  const [searchInput, setSearchInput] = useState<string>("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(1);

  const { active, setActive } = useIsProductStore((state) => ({
    active: state.active,
    setActive: state.setActive,
  }));

  const [startDate, setStartDate] = useState<string>(getTodayDate());
  const [endDate, setEndDate] = useState<string>(getTodayDate());

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [list, setList] = useState<IStockReport[] | undefined>(undefined);

  const getStockReportApi = async () => {
    try {
      const response = await StockReportService.getReportApi(
        startDate,
        endDate
      );
      if (
        response.status === 200 &&
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setList(response.data.data);
        // setTotalCount(response.data.data.totalProducts);
        setState({ ...state, loader: false });
        setActive(false);
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    getStockReportApi();
  }, [page, rowsPerPage, startDate, endDate]);
  useEffect(() => {
    if (active) {
      getStockReportApi();
    }
  }, [active]);

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Add 1 to newPage to make it 1-based index
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };
  const getRowsPerPageOptions = () => {
    if (+totalCount <= 10) {
      return []; // returns an empty array if totalCount is 10 or less
    }
    return [10, 25, 50];
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  const onDateChange = (value: string, isStart: boolean) => {
    if (isStart) {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  return (
    <>
      <div>
        <Paper sx={{ width: "100%" }} elevation={3}>
          <THeader
            searchInputHandler={searchInputHandler}
            searchInput={searchInput}
            startDate={startDate}
            endDate={endDate}
            onDateChange={onDateChange}
          />
          <MTable>
            <THead />

            <TableBody>
              {list?.map((data, index) => {
                return <TRow data={data} index={index} key={index} />;
              })}
            </TableBody>
          </MTable>
          <TablePagination
            component="div"
            count={+totalCount}
            page={+page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={+rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions()}
          />
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default List;
