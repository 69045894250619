import { IconButton, InputBase, Stack, Tooltip } from "@mui/material";
import { BasicSearch } from "../SearchBar";
import Iconify from "../Iconify";
// ----------------------------------------------------------------------
interface IProps {
  searchInputHandler: (value: string) => void;
  searchInput: string;
  startDate: string;
  endDate: string;
  onDateChange: (event: string, isStart: boolean) => void;
}
// ----------------------------------------------------------------------

const THeader = ({
  searchInputHandler,
  searchInput,
  startDate,
  endDate,
  onDateChange,
}: IProps) => {
  // const [date, setDate] = useState<string>(getTodayDate());
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      p={2}
    >
      <BasicSearch
        searchInputHandler={searchInputHandler}
        searchInput={searchInput}
        width={"250px"}
      />
      <Stack direction="row" gap={2}>
        <InputBase
          type="date"
          value={startDate}
          disabled={false}
          onChange={(e) => onDateChange(e.target.value, true)}
          sx={{
            "& .MuiInputBase-input": {
              position: "relative",
              backgroundColor: "background.paper",
              border: (theme) => `1px solid #ccc`,
              px: 1,
              py: 1,
              borderRadius: 3,
            },
          }}
        />
        <InputBase
          type="date"
          value={endDate}
          disabled={false}
          onChange={(e) => onDateChange(e.target.value, false)}
          sx={{
            "& .MuiInputBase-input": {
              position: "relative",
              backgroundColor: "background.paper",
              border: (theme) => `1px solid #ccc`,
              px: 1,
              py: 1,
              borderRadius: 3,
            },
          }}
        />
        <Tooltip title="Download Excel">
          <IconButton>
            <Iconify icon="tabler:download" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="fluent:filter-12-filled" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
export default THeader;
