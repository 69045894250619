import create from "zustand";

type Store = {
  weight: string;
  setWeight: (data: string) => void; // Optional parameter to allow clearing the data
};

const useWeightStore = create<Store>((set) => ({
  weight: "1.000",
  setWeight(weight: string) {
    set({ weight: weight });
  },
}));

export default useWeightStore;
