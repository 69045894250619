import {
  Container,
  Typography,
  Paper,
  Box,
  Stack,
  Button,
  Chip,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import ModelOpen from "../../../core/Model copy";
import { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { roundNumber } from "../../../hooks/roundNumber";

export interface IModel {
  open: boolean;
  handleClose: () => void;
  cashAmt: number;

  children?: React.ReactNode;
  title?: string;
  subTitle?: string;
  callApiHandler: () => void;
  amount: number;
  paymentMode: "UPI" | "Cash";
  setPaymentMode: (data: "UPI" | "Cash") => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const payemtType = ["UPI", "Cash"];

const ConfirmTosted = ({
  open,
  handleClose,
  title,
  subTitle,
  amount,
  callApiHandler,
  paymentMode,
  setPaymentMode,
  handleInputChange,
  cashAmt,
}: IModel) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  // const [receive, setReceive] = useState<number>(0);

  // useEffect(() => {
  //   setReceive(0);
  // }, [open]);

  const [type, setType] = useState<"UPI" | "Cash">("Cash");

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value == "UPI" || event.target.value == "Cash") {
      setType(event.target.value);
      setPaymentMode(event.target.value);
    }
  };

  const handleInputBlur = () => {
    setEditMode(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (type === "Cash") {
      if (cashAmt >= Math.abs(cashAmt - roundNumber(amount))) {
        callApiHandler();
      } else {
        alert(
          "Attention: The entered cash amount does not match the bill total."
        );
      }
    } else {
      callApiHandler();
    }
  };

  return (
    <ModelOpen
      open={open}
      handleClose={handleClose}
      onModelHandler={() => console.log("onModelHandler")}
      title={title}
      subTitle={subTitle}
      width="450px"
    >
      <form
        className="column"
        onSubmit={handleSubmit}
        // onKeyDown={handleKeyDown}
      >
        <Container
          maxWidth="lg"
          component={Paper}
          elevation={2}
          sx={{ width: "100%" }}
        >
          <Typography variant="h4" textAlign={"start"} gutterBottom>
            {title}
          </Typography>
          <Typography textAlign={"start"} gutterBottom>
            {subTitle}
          </Typography>
          <Box py={2}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              width={"100%"}
              alignItems={"center"}
              height={"100%"}
            >
              <Typography fontWeight={"900"} sx={{ fontSize: "20px" }}>
                Payment Type :
              </Typography>

              <FormControl sx={{ minWidth: 120 }} size="medium">
                <Select
                  value={type}
                  onChange={handleChange}
                  sx={{ fontWeight: 900, fontSize: 20 }}
                >
                  {payemtType.map((data) => {
                    return (
                      <MenuItem
                        value={data}
                        sx={{ fontWeight: "900", fontSize: "20px" }}
                      >
                        {data}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>

            {type === "Cash" && (
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                pt={2}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    textAlign: "center",
                    fontWeight: 900,
                  }}
                >
                  Received Amt :
                </Typography>

                <TextField
                  value={cashAmt === 0 ? "" : cashAmt}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  autoFocus
                  type="number"
                  size="medium"
                  sx={{
                    fontSize: "25px",
                    fontWeight: 900,
                    textAlign: "center",
                    width: "100px",
                    "& .MuiOutlinedInput-input": {
                      fontWeight: 900,
                      fontSize: "25px",
                      border: "none",
                    },
                  }}
                />
              </Stack>
            )}

            <Stack direction={"row"} justifyContent={"space-between"} pt={1}>
              <Typography
                sx={{
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
              >
                Total :
              </Typography>
              <Typography
                sx={{
                  fontSize: "25px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
                color={"primary"}
              >
                {amount.toFixed(2)}
              </Typography>
            </Stack>

            <Stack direction={"row"} justifyContent={"space-between"} pt={1}>
              <Typography
                sx={{
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
              >
                Round Off :
              </Typography>
              <Typography
                sx={{
                  fontSize: "25px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
                color={"primary"}
              >
                {amount - roundNumber(amount) > 0 ? "-" : "+"}
                {Math.abs(roundNumber(amount) - amount).toFixed(2)}
              </Typography>
            </Stack>

            <Stack direction={"row"} justifyContent={"space-between"} pt={1}>
              <Typography
                sx={{
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
              >
                Net :
              </Typography>
              <Typography
                sx={{
                  fontSize: "25px",
                  textAlign: "center",
                  fontWeight: 900,
                }}
                color={"primary"}
              >
                {roundNumber(amount) + ".00"}
              </Typography>
            </Stack>

            {type === "Cash" && (
              <>
                <Box sx={{ border: "2px dashed" }}></Box>

                {cashAmt >= roundNumber(amount) && (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    pt={1}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        textAlign: "center",
                        fontWeight: 900,
                      }}
                      color={"primary"}
                    >
                      Return :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "25px",
                        textAlign: "center",
                        fontWeight: 900,
                      }}
                      color={"primary"}
                    >
                      {cashAmt - roundNumber(amount) + ".00"}
                    </Typography>
                  </Stack>
                )}

                {cashAmt < roundNumber(amount) && (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"end"}
                    pt={1}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        textAlign: "center",
                        fontWeight: 900,
                      }}
                      color={"error"}
                    >
                      Pending :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "25px",
                        textAlign: "center",
                        fontWeight: 900,
                      }}
                      color={"error"}
                    >
                      {Math.abs(cashAmt - roundNumber(amount)) + ".00"}
                    </Typography>
                  </Stack>
                )}
              </>
            )}

            <Stack direction={"row"} gap={"10px"} pt={2}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                color="primary"
                sx={{ py: 2, fontWeight: 900, fontSize: "20px" }}
              >
                Pay Now
              </Button>
            </Stack>
          </Box>
        </Container>
      </form>
    </ModelOpen>
  );
};

export default ConfirmTosted;
