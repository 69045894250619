import create from "zustand";
type Store = {
  name: string;
  setName: (name: string) => void; // Optional parameter to allow clearing the data
};

const useNameStore = create<Store>((set) => ({
  name: "",
  setName(name: string) {
    set({ name: name });
  },
}));

export default useNameStore;
