import { InstanceAxios } from "../hooks/axiosConfig";

export class BillService {
  public static getUnpaidBillsAlpi() {
    return InstanceAxios().get("/posbill/getUnpaidBills");
  }
  public static getPreviousBillsAlpi() {
    return InstanceAxios().get("/posbill/getLastPaidBill");
  }

  public static getBillNoByIdAlpi(billNo: number) {
    return InstanceAxios().get("/posbill/getBillByNumber", {
      params: {
        billNo: billNo,
      },
    });
  }
}
